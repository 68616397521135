import React, { useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
import dig from "object-dig";


export const Header = () => {
    const currentUser = useContext(AuthContext);

    // ログインしていない場合に特定のクラスを追加
    const headerClassName = dig(currentUser, 'currentUser', 'uid') 
    ? "p-header" 
    : "p-header p-header--guest";

    return(
        <>
            <header className={headerClassName}>
            <img
            className="p-header__img"
            src="fv-img.png"
            alt="simple todo"
            />
            <div className="p-header__txts">
            <p className="p-header__copy u-txtUpper">
                毎日のことだから
                <br />
                とにかくシンプルに。
            </p>
            <h1 className="p-header__h1">タスク管理アプリ SIMPLE TODO</h1>
            </div>
        </header>
        </>
    );
}