import { CompletedTodoItem } from './CompletedTodoItem';
import { Spinner } from "./Spinner";

export const CompleteTodos = (props) => {
  const { todoItems, fetchTodos, toggleComplete, getFormattedDate, loadingTasks } = props;
  if (loadingTasks) {
    // データ取得中はスピナーを表示
    return <Spinner />;
  }
    return (
      <div className="p-doneArea">
        <p className="c-heading u-txtUpper">complate</p>
        <ul id="js-doneList" className="c-tasklist">
          {todoItems.map((incompleteTodo) => {
            const formattedCompleteAt = getFormattedDate(incompleteTodo.completeAt);
            const checkHandle = async (id) => {
              await toggleComplete(id);
              fetchTodos();
            }

            if(incompleteTodo.isComplete){
              return(
                <CompletedTodoItem
                key={incompleteTodo.id}
                incompleteTodo={incompleteTodo}
                formattedCompleteAt={formattedCompleteAt}
                checkHandle={checkHandle}
              />
              );
            }
            return null;
          })}
        </ul>
      </div>
    );
  };
  