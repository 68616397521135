import { DueDate } from './DueDate';
export const InputTodo = (props) => {
    const { todoText, onChangeTodoText, postTodo, disabled, setDueDate, dueDate } = props;
    return (
      <div className="p-inputArea">
         <div className="p-inputArea__inner">
          <input
            className="p-inputarea__input"
            type="text"
            placeholder="タスクを入力"
            value={todoText}
            onChange={onChangeTodoText}
          />{' '}
          <DueDate
            dueDate={dueDate}
            setDueDate={setDueDate}
          />
          <button
            type='button'
            disabled={disabled}
            onClick={postTodo}
            className="c-addBtn p-inputArea__addbtn"
          >追加</button> 
        </div>
      </div>
    );
  };
  