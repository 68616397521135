import { TodoItem } from './TodoItem';
import { Spinner } from "./Spinner";

export const InCompleteTodos = (props) => {
    const { todoItems, todoDelete, fetchTodos, toggleComplete,  getFormattedDate, startEditing, endEditing, editingTodoId, newContent, setNewContent, loadingTasks, formattedDate, setNewDueDate, newDueDate } = props;
    if (loadingTasks) {
      // データ取得中はスピナーを表示
      return <Spinner />;
    }
    return (
      <div className="p-incompleteArea">
        <p className="c-heading u-txtUpper">TODO</p>
        <ul id="js-incompleteList" className="c-tasklist">
          {todoItems.map((incompleteTodo) => {
            const formattedCreatedAt = getFormattedDate(incompleteTodo.createAt);
            const formattedUpdateAt = getFormattedDate(incompleteTodo.updateAt);
            const formattedDueDate = getFormattedDate(incompleteTodo.dueDate);
            const deleteHandle = (id) => {
              todoDelete(id);
              fetchTodos();
            }
            const checkHandle = async (id) => {
              await toggleComplete(id);
              fetchTodos();
            }
            if(incompleteTodo.isComplete === false){
              return(
                <TodoItem
                key={incompleteTodo.id}
                incompleteTodo={incompleteTodo}
                editingTodoId={editingTodoId}
                newContent={newContent}
                setNewContent={setNewContent}
                startEditing={startEditing}
                endEditing={endEditing}
                deleteHandle={deleteHandle}
                checkHandle={checkHandle}
                formattedCreatedAt={formattedCreatedAt}
                formattedUpdateAt={formattedUpdateAt}
                formattedDueDate={formattedDueDate}
                formattedDate={formattedDate}
                setNewDueDate={setNewDueDate}
                newDueDate={newDueDate}
              />
              );
            }
            return null;
          })}
        </ul>
      </div>
    );
  };
  