import { DateDisplay } from './DateDisplay';
import { DueDate } from './DueDate';

export const TodoItem = (props) => {
    const { startEditing, endEditing, editingTodoId, newContent, setNewContent, checkHandle, incompleteTodo, formattedCreatedAt, formattedUpdateAt, formattedDueDate,  deleteHandle, formattedDate, newDueDate, setNewDueDate } = props;
    // 期限切れタスクの場合、クラスを追加する
    const checkOverdue = formattedDate > formattedDueDate.year + "/" + formattedDueDate.month + "/" + formattedDueDate.date
        ? "c-tasklist__item is-overdue"
        : "c-tasklist__item";
    return (
        <li className={checkOverdue} >
            <div className="c-tasklist__head">
                <input
                type="button"
                onClick={() => checkHandle(incompleteTodo.id)}
                className="c-tasklist__doneBtn c-doneBtn"
                ></input>
                {/* 編集モードかどうかで表示を切り替える */}
                {editingTodoId === incompleteTodo.id ? (
                <input
                    type="text"
                    value={newContent}
                    onChange={(e) => setNewContent(e.target.value)}
                    className="p-inputarea__input"
                />
                ) : (
                <p className="c-tasklist__name">{incompleteTodo.content}</p>
                )}
            </div>
            <div className="c-tasklist__body">
                <div>
                    { /* 更新日が存在する場合は更新日を表示 */ }
                    {formattedCreatedAt.year + formattedCreatedAt.month + formattedCreatedAt.date === formattedUpdateAt.year + formattedUpdateAt.month + formattedUpdateAt.date  ? 
                        (
                        <DateDisplay dateType="created" formattedDate={formattedCreatedAt} />
                        )
                        : 
                        (
                        <DateDisplay dateType="updated" formattedDate={formattedUpdateAt} />
                        )
                    }
                    { /* 編集モードかどうかで表示を切り替える */ }
                    {editingTodoId === incompleteTodo.id ? 
                    (
                        <DueDate
                        editingFlag={true}
                        newDueDate={newDueDate}
                        setNewDueDate={setNewDueDate}
                        />
                        )
                    :
                    <DateDisplay dateType="duedate" formattedDate={formattedDueDate}  />
                    }
                </div>

                <div className="c-tasklist__btnwrap">
                {/* 編集モードかどうかで表示を切り替える */}
                {editingTodoId === incompleteTodo.id ? 
                    (
                    <button 
                    onClick={() => {
                        endEditing(incompleteTodo);
                    }}
                    className="c-inputBtn -done">保存</button>
                    ) : (
                    <button 
                    onClick={() => {
                        startEditing(incompleteTodo);
                    }}
                    className="c-inputBtn -done">編集</button>
                    )
                }
                <button
                    type="button"
                    onClick={() => {
                    deleteHandle(incompleteTodo.id);
                    }}
                    className="c-icon-trash"
                ></button>
                </div>
            </div>
            </li>
    )
}