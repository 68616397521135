import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { registerLocale } from "react-datepicker";

//日本語化
registerLocale("ja", ja);

export const DueDate = (props) => {
    const { setDueDate, dueDate, setNewDueDate, newDueDate, editingFlag } = props;
    const today = new Date();

    const aaa = editingFlag === true ?
    (
        <div className="c-tasklist__time">
            <span className="dueDate-edit">
            期限：<DatePicker
            locale="ja"
            selected={newDueDate}
            onChange={(date) => setNewDueDate(date)}
            minDate={today}
            dateFormat="yyyy/MM/dd" 
            id="p-inputarea__date"  
            className="p-datepicker"
            popperPlacement="top"
            />
            </span>
        </div>
    )
    :
    (
        <>
            <DatePicker
            locale="ja"
            isClearable={dueDate ? true : false} 
            selected={dueDate}
            onChange={(date) => setDueDate(date)}
            placeholderText="期限を入力"
            minDate={today}
            dateFormat="yyyy/MM/dd" 
            id="p-inputarea__input"  
            className="p-datepicker"
            popperPlacement="top"
            />
            <small className="u-txt__right u-small u-txt__gray">※未入力の場合：タスク作成日となります</small>
        </>
    );
    return aaa;
};