export const Footer = () => {
    return (
      <footer className="p-footer">
        <div className="p-footer__inner">
          {/* <ul className="p-footer__menu">
            <li>
              <a href="">ホーム</a>
            </li>
            <li>
              <a href="">開発者について</a>
            </li>
            <li>
              <a href="">お問い合わせ</a>
            </li>
          </ul> */}
          <div className="p-footer__copy">©Mucca 2024. All Rights Reserved.</div>
        </div>
      </footer>
    );
  };
  