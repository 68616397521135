import { useEffect } from "react";

const ViewportHandler = () => {
  useEffect(() => {
    const viewport = document.querySelector('meta[name="viewport"]');

    // viewportを切り替える関数
    const switchViewport = () => {
      const value =
        window.outerWidth > 420
          ? 'width=device-width,initial-scale=1'
          : 'width=420';
      if (viewport.getAttribute('content') !== value) {
        viewport.setAttribute('content', value);
      }
    };

    // リサイズイベントにリスナーを追加
    window.addEventListener('resize', switchViewport);

    // 初回実行
    switchViewport();

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('resize', switchViewport);
    };
  }, []);

  return null; // このコンポーネントはDOMには何も描画しません
};

export default ViewportHandler;
