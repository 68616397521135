import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const provider = new GoogleAuthProvider();

// 永続化の有効化
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    // 多重タブが原因で永続化が有効化できない場合のエラーハンドリング
    console.error('Persistence failed due to multiple tabs open.');
  } else if (err.code === 'unimplemented') {
    // ブラウザが永続化をサポートしていない場合のエラーハンドリング
    console.error('Persistence is not supported in this browser.');
  }
});

export const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
    .then((result) => {
      GoogleAuthProvider.credentialFromResult(result);
    }).catch((error) => {
      // Handle Errors here.
    });
}

export const signOutWithGoogle = () => {
    signOut(auth).then(() => {
        // Sign-out successful.
        console.log('Sign-out successful.');
      }).catch((error) => {
        // An error happened.
        console.log(error);
      });
}