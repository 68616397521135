import React, { useState, useEffect} from "react";
import { auth } from '../service/firebase'
import { Spinner } from "../components/Spinner";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true); // ローディング状態を追加
    
    // AuthProviderがマウント（実行）された時1回だけ実行する
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false); // 認証状態が確認できたらローディングを解除
        })
        return () => unsubscribe();
    },[]);

    if (loading) {
        // 認証状態の確認中はローディングインジケーターを表示
        return <Spinner />;
    }

    return (
        // Contextを使用して、認証に必要な情報をコンポーネントツリーに流し込む
        <AuthContext.Provider value={{ currentUser }} >
            {children}
        </AuthContext.Provider>
    );
}