import React, { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { signOutWithGoogle, signInWithGoogle } from "../service/firebase";
import dig from "object-dig";
import { InputTodo } from './InputTodo';
import { InCompleteTodos } from './InCompleteTodos';
import { CompleteTodos } from './CompleteTodos';
import * as Api from "../service/api";
import { Spinner } from "./Spinner";

export const Dashboard = () => {
    /*
    * ステートの宣言
    */
    const [todoText, setTodoText] = useState('');
    const [todoItems, setTodoItems] = useState([]);
    const [editingTodoId, setEditingTodoId] = useState(null); // 編集モードのタスクID
    const [newContent, setNewContent] = useState(""); // 編集中のタスク内容
    const [newDueDate, setNewDueDate] = useState("2024/11/30"); // 編集中の期限
    const [loadingTasks, setLoadingTasks] = useState(true); // データ取得ローディングを管理する
    const [dueDate, setDueDate] = useState(null);

    /*
    * コンテキストから現在のユーザーを取得
    */
    const currentUser = useContext(AuthContext);

    /*
    * 入力欄（タスク）の値を取得
    */
    const onChangeTodoText = (e) => setTodoText(e.target.value);

    /*
    * 今日の日付を取得してフォーマットする（yy/mm/dd）
    */
    const date = new Date();
    const formattedDate = date.toLocaleDateString("ja-JP", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    });


    /*
    * タスク追加（入力がない場合は「無題」として追加）
    */
    const postTodo = async () => {
      const textToAdd = todoText.trim() === '' ? '無題' : todoText;
      await Api.addTodo(textToAdd,currentUser.currentUser.uid,dueDate);
      await setTodoText('');
      await setDueDate('');
      fetchTodos();
    }

    /*
    * タスクの取得
    */
    const initialFetchTodos = useCallback(async () => {
      if (dig(currentUser, 'currentUser', 'uid')) { 
        // 初回はログイン情報が取得できていないため、その場合はDBに情報をとりに行かない条件分岐を実装
        setLoadingTasks(true);
        const getTodoItems = await Api.initGet(currentUser.currentUser.uid);
        await setTodoItems(getTodoItems);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoadingTasks(false);
      }
    }, [currentUser]); // currentUser を依存関係に追加
    useEffect(() => {
      // todo一覧を取得する
      initialFetchTodos();
    }, [initialFetchTodos]); // 依存関係は initialFetchTodos のみ

    const fetchTodos = async() => {
      const getTodoItems = await Api.initGet(currentUser.currentUser.uid);
      await setTodoItems(getTodoItems);
    }

    /*
    * タスクを編集する（期限が更新日よりも前になる場合はアラートを出す）
    */
    const startEditing = (incompleteTodo) => { // 編集ボタンをクリックしたら動く関数
      setEditingTodoId(incompleteTodo.id);
      setNewContent(incompleteTodo.content);
      const formattedDueDate = getFormattedDate(incompleteTodo.dueDate);
      setNewDueDate(formattedDueDate.year + '/' + formattedDueDate.month + '/' + formattedDueDate.date);
    };
    // 編集が完了したら、新しい内容でタスクを更新するための関数
    const endEditing = async (incompleteTodo) => { 
      const editDuedate = typeof newDueDate === "string" // 初期値が文字列（yyyy/mm/dd形式）の場合はDateに変換
      ? convertToDate(newDueDate)
      : newDueDate;
      await Api.saveEdit(incompleteTodo.id,newContent,editDuedate);
      setEditingTodoId(null);
      fetchTodos();
    };


    /*
    * タイムスタンプをフォーマットする
    */
    const getFormattedDate = (serverTimestamp) => { // 
      serverTimestamp.year = serverTimestamp.toDate().getFullYear();
      serverTimestamp.month = ("0" + (serverTimestamp.toDate().getMonth() + 1)).slice(-2);
      serverTimestamp.date = ("0" + serverTimestamp.toDate().getDate()).slice(-2);
      return serverTimestamp;
    }
    // yyyy/mm/ddの文字列をDateオブジェクトに変換する関数
    const convertToDate = (dateString) => {
      const [year, month, day] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day);
    };

    /*
    * 未完了TODOの上限チェック
    */
    const isMaxLimitIncompleteTodos = todoItems.length >= 100;  

    /*
    * 認証
    */
    const AuthButton = () => {
      const handleSignInWithGoogle = () => {
          signInWithGoogle(); // Googleサインインの処理
      };
      const handleSignOut = () => {
          signOutWithGoogle(); // サインアウトの処理
      };
      const authButton = dig(currentUser, 'currentUser', 'uid') ? (
          <div
          className="u-txt__right">
              <button 
              className="c-logoutLink"
              onClick={handleSignOut}>ログアウト</button>
          </div>
      ) : (
          <button
          className="c-loginBtn"
          onClick={handleSignInWithGoogle}>ログイン</button>
      );
      return authButton;
  };


    const renderTodoForm = () => {
        const userUid = dig(currentUser, 'currentUser', 'uid');
        if (!userUid) {
          return (
            <>
            {AuthButton()}
            </>
          );
        }
        if (loadingTasks) {
          // データ取得中はスピナーを表示
          return <Spinner />;
        }
        return (
            <>
              {AuthButton()}
              <InputTodo
                todoText={todoText}
                onChangeTodoText={onChangeTodoText}
                postTodo={postTodo}
                disabled={isMaxLimitIncompleteTodos}
                dueDate={dueDate}
                setDueDate={setDueDate}
              />
              {isMaxLimitIncompleteTodos && (
                <p className="c-errorMsg">
                  登録できるTODOの上限（５つ）を超えています。
                  TODOを完了してください。
                </p>
              )}
              <InCompleteTodos
                todoItems={todoItems}
                todoDelete={Api.todoDelete}
                toggleComplete={Api.toggleComplete}
                fetchTodos={fetchTodos}
                getFormattedDate={getFormattedDate}
                startEditing={startEditing}
                endEditing={endEditing}
                editingTodoId={editingTodoId}
                newContent={newContent}
                setNewContent={setNewContent}
                loadingTasks={loadingTasks}
                formattedDate={formattedDate}
                setNewDueDate={setNewDueDate}
                newDueDate={newDueDate}
                // onClickComplete={onClickComplete}
                // onClickDelete={onClickDelete}
              />
              <CompleteTodos
                todoItems={todoItems}
                toggleComplete={Api.toggleComplete}
                fetchTodos={fetchTodos}
                getFormattedDate={getFormattedDate}
                loadingTasks={loadingTasks}
                // onClickRestore={onClickRestore}
              />
            </>
        );
    }

    return (
        <div>
            {renderTodoForm()}
        </div>
    );
}