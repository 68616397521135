import { DateDisplay } from './DateDisplay';

export const CompletedTodoItem = (props) => {
    const {formattedCompleteAt,incompleteTodo, checkHandle} = props;

    return (
        <li className="c-tasklist__item -checked">
            <div className="c-tasklist__head">
            <button
                type="button"
                onClick={() => checkHandle(incompleteTodo.id)}
                className="c-tasklist__doneBtn c-restoreBtn -checked"
            ></button>
            <p className="c-tasklist__name">{incompleteTodo.content}</p>
            </div>
            <div className="c-tasklist__body">
            <DateDisplay dateType="completed" formattedDate={formattedCompleteAt} />
            </div>
        </li>
    )
}