// 日付表示用の汎用コンポーネント
export const DateDisplay = ({ dateType, formattedDate }) => {
  // 日付が存在しない場合のチェック
  if (!formattedDate) {
    return null; // フォーマットされた日付がない場合は何も表示しない
  }

  // 日付の種類（作成日、更新日、完了日）に応じて表示テキストを変更
  const dateLabel = dateType === "created"
    ? "作成"
    : dateType === "updated"
    ? "更新"
    : dateType === "completed"
    ? "完了"
    : dateType === "duedate"
    ? "期限"
    : "日付";

  return (
    <time
      className="c-tasklist__time"
      dateTime={`${formattedDate.year}-${formattedDate.month}-${formattedDate.date}`}
    >
      {dateLabel}：{formattedDate.year}/{formattedDate.month}/{formattedDate.date}
    </time>
  );
};
