import './App.css';
import './service/firebase';
import { Header } from './components/Header';
import { AuthProvider } from './providers/AuthProvider';
import { Footer } from './components/Footer';
import { Dashboard } from './components/Dashboard';
import ViewportHandler from "./components/ViewportHandler";

const App = () => {
  return (
    <AuthProvider>
      <ViewportHandler />
      <div className="l-rootContainer">
      <Header />
      <Dashboard />
      <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
