/* 
* 通信用のファイル
*/

import { db } from "./firebase";
import { collection, doc, getDoc, addDoc, deleteDoc, updateDoc, serverTimestamp, query, where, getDocs, orderBy } from "firebase/firestore";

// todoをDBに追加する
export const addTodo = async (content, uid, dueDate) => {
  try {
    await addDoc(collection(db, "todo"), {
      content: content,
      createAt: serverTimestamp(),
      isComplete: false,
      uid: uid,
      dueDate: dueDate ? dueDate : serverTimestamp()

    });
  } catch (error) {
    console.error("Error adding document: ", error);
  }
}

// ログインしているユーザのtodoを取得する
export const initGet = async (uid) => { 
  const q = query(collection(db, "todo"), orderBy("createAt","desc"), where("uid", "==", uid));
  const querySnapshot = await getDocs(q); // 全てのtodoが取得できるまで次の処理を待つ
  let todoItems = [];
  querySnapshot.forEach((doc) => {
    todoItems.push({
          id: doc.id,
          content: doc.data().content,
          isComplete: doc.data().isComplete,
          createAt: doc.data().createAt,
          completeAt: doc.data().completeAt ? doc.data().completeAt : doc.data().createAt,
          updateAt: doc.data().updateAt ? doc.data().updateAt : doc.data().createAt ,
          dueDate: doc.data().dueDate ? doc.data().dueDate : doc.data().createAt 
        })
  });
  return todoItems;
}

// todoを削除する
export const todoDelete = (id) => {
  deleteDoc(doc(db, "todo", id));
}

// 未完了todoを完了のステータスに更新する
export const toggleComplete = async (id) => {
  const todo = doc(db, "todo", id);
  const docSnap = await getDoc(todo);
  if (docSnap.exists()) {
    const data = docSnap.data();
   await updateDoc(todo, {
      isComplete: data.isComplete ? false : true,
      completeAt: serverTimestamp()
    });
  }
}

// 編集が完了したら、新しい内容でタスクを更新するための関数
export const saveEdit = async (id, newContent, newDueDate) => {
  const todo = doc(db, "todo", id);
  await updateDoc(todo, {
    id: id,
    content: newContent,
    updateAt: serverTimestamp(),
    dueDate: newDueDate
  });
};